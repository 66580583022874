import React from 'react';
import './App.css';
import 'mailgo';
import { Bootstrap, Grid, Row, Col, Footer } from 'react-bootstrap';
class App extends React.Component {
  static defaultProps = {
    colorPalettes: [
      {primary: '#4AB8F9', secondary: '#0E2373'}, //azul
      {primary: '#60B332', secondary: '#467F3B'}, //verde
      {primary: '#FF6600', secondary: '#FFBF00'}, //naranja
      {primary: '#FF559D', secondary: '#D01760'} //rosado
    ],
    colors: { dark:"#000000", light: "#E1E1DF" },
    darkRGB: "rgb(34, 38, 41)"
  }
  constructor() {
    super()
    this.state = {
      x:-38,
      y:-27,
      z:0,
      zoom:-150,
      opacity:100,
      perspective:900,
      zoomRange:[-1500,0],
      clicksCount: 0
    }
    this.onMouseMove = this.onMouseMove.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    function fadeIn(el, display){
      el.style.opacity = 0;
      el.style.display = display || "block";

      (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val += .01) > 1)) {
          el.style.opacity = val;
          requestAnimationFrame(fade);
        }
      })();
    };

    function fadeOut(el){
      el.style.opacity = 1;

      (function fade() {
        if ((el.style.opacity -= .1) < 0) {
          el.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    };

    function nextSlide(slideIndex, slides) {
      let counters = document.getElementsByClassName('slides-counter')[0].children;
      [].forEach.call(counters, function(el) { el.classList.remove("active"); });
      counters[slideIndex].classList.add("active");
      if (slideIndex == 0) {
        slides[slides.length - 1].style.display = 'none';
        slides[0].style.display = 'block';
        counters[slideIndex].classList.add('active');
      } else {
        slides[slideIndex -1].style.display = 'none';
        slides[slideIndex].style.display = 'block';
      }
    }

    fadeIn(document.getElementsByClassName('container')[0]);
    fadeIn(document.getElementsByClassName('jumbotron')[0]);
    fadeIn(document.getElementsByClassName('footer')[0]);
    fadeIn(document.getElementsByClassName('navbar')[0]);

    document.addEventListener('mousemove', this.onMouseMove);
    let slideIndex = 0;
    let slides = document.getElementsByClassName('jumbotron');
    window.setInterval(function () {
      slideIndex++;
      if( slideIndex == 4 ) { slideIndex = 0; }
      nextSlide(slideIndex, slides);
    }, 5000);
  }

  randomize() {
    this.setState({
      x:Math.random(-90,90), y:Math.random(-90,90), z:Math.random(-90,90),
      zoom:Math.random(this.state.zoomRange[0], this.state.zoomRange[1])
    })
  }

  colorize() {
    let colorPalettes = this.props.colorPalettes;
    var position = Math.floor(Math.random()*((colorPalettes.length -1)-0+1)+0);
    var thisTime = colorPalettes[position]
    let cube = document.getElementsByClassName('cube')[0]
    cube.children[4].style["backgroundColor"] = thisTime.primary;
    cube.children[5].style["backgroundColor"] = thisTime.secondary;
  }

  lightSwitch() {
    let phrases = [
      'this behaviour is not very animal friendly... gotta think of your mouse',
      'have you heard of something called Epilepsy?',
      "procastinating, aren't we?",
      'you think your boss will be happy?',
      'calling The Click Police right away!',
      'thanks for skyrocketing our click-counts on GA!',
      'Peralta! pull yourself together'
    ];
    this.state.clicksCount++;
    if (this.state.clicksCount % 4 == 0) {
      let randomNbr = Math.floor(Math.random()*((phrases.length -1)-0+1)+0)
      alert(phrases[randomNbr]);
    }
    let colors = this.props.colors;
    let otherElements = [
      document.getElementsByClassName('name-logo')[0],
      document.getElementsByClassName('navbar-brand')[0]
    ]

    if (document.body.style.backgroundColor == this.props.darkRGB || document.body.style.backgroundColor == '' || document.body.style.backgroundColor == 'rgb(0, 0, 0)') {
      document.body.style.backgroundColor = colors.light;
      document.body.style.color = colors.dark;
      for(var i = 0; i < otherElements.length; i++) {
        otherElements[i].style.color = colors.dark;
      }
      document.getElementsByClassName('light-switch')[0].style.borderColor = colors.dark;
      document.getElementsByClassName('name-logo')[0].style.borderColor = colors.dark;
    } else {
      document.body.style.backgroundColor = colors.dark;
      document.body.style.color = colors.light;
      for(var i = 0; i < otherElements.length; i++) {
        otherElements[i].style.color = colors.light;
      }
      document.getElementsByClassName('light-switch')[0].style.borderColor = colors.light;
      document.getElementsByClassName('name-logo')[0].style.borderColor = colors.light;
    }

    this.setState({x:-38, y:-27, z:0})
  }

  handleClick(e) {
    this.colorize()
  }

  onMouseMove(e) {
    if (!this.state.displayPanel) {
      var hh = window.innerHeight/2, hw = window.innerWidth/2
      var limitedX = (hh-e.clientY)/hh*90;
      var limitedY = (e.clientX-hw)/hw*90;

      this.setState({x:limitedX, y:limitedY, z:0})
    }
  }

  render() {
    var {x, y, z, zoom, zoomRange, opacity, perspective, displayPanel} = this.state,
        cubeStyle = { transform: `translateZ(${zoom}px) rotateX(${x}deg) rotateY(${y}deg) rotateZ(${z}deg)` },
        containerStyle = { perspective: `${perspective}px` },
        surfaceStyle = { opacity: 1 }

    return (
      <div>
        <nav class="navbar navbar-light bg-light">
          <a href="mailto:hello@knesis.com" class="navbar-brand mb-0 h1">get in touch</a>
        </nav>
        <div class="interactive-content" onClick={this.handleClick}>
          <div className="wrapper">
            <div className="container" style={containerStyle}>
              <div class="row">
                <div class="col" className={displayPanel ? 'cube' : 'cube animated'} style={cubeStyle}>
                  {Array(6).fill().map(() => <figure style={surfaceStyle} />)}
                </div>
              </div>
            </div>
          </div>

          <div class="jumbotrons">

            <div class="jumbotron jumbotron-fluid">
              <div class="container text">
                <h1 class="display-4">loving what we do!</h1>
                <p class="lead">our specialty is to craft superbly built solutions</p>
                <p class="lead">using cutting edge technologies</p>
                <p class="lead"></p>
                <p class="lead">keep IT moving...</p>
              </div>
            </div>

            <div class="jumbotron jumbotron-fluid">
              <div class="container text">
                <h1 class="display-5">Vanesa Telias</h1>
                <p class="lead">I'm the responsible one</p>
                <p class="lead"></p>
                <p class="lead">drop us a line</p>
                <p class="lead">and work with us</p>
              </div>
            </div>

            <div class="jumbotron jumbotron-fluid">
              <div class="container text">
                <h1 class="display-4">Mathias Caldas</h1>
                <p class="lead">...aim ...shoot & bullseye!</p>
                <p class="lead"></p>
                <p class="lead"></p>
                <p class="lead">THe one</p>
              </div>
            </div>

            <div class="jumbotron jumbotron-fluid">
              <div class="container text">
                <h1 class="display-4">Seba Castro</h1>
                <p class="lead">↑↑ ↓↓ ← → ← → B A</p>
                <p class="lead"></p>
                <p class="lead"></p>
                <p class="lead">Now we're talking.</p>
              </div>
            </div>
          </div>
          <div class="slides-counter">
            <p class="active">.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
          </div>
        </div>
        <div class="footer">
          <p class="name-logo">k|nesis.com</p>
          <p>169 11th St, San Franciso, CA 94103</p>
          <p class="light-switch" onClick={this.lightSwitch.bind(this)}>lights</p>
        </div>
      </div>
    )
  }
}

export default App;
